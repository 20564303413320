<template>
  <div v-if="entity && entity.information">
    <div class="services-header">
      <h1>Services</h1>
      <div class="mr-4 d-none d-sm-block kart" v-if="services.length > 0">
        <div
          style="width: 40px; height: 40px; cursor: pointer"
          @click="kartClickHandler"
        >
          <a-badge
            :count="kart.length"
            style="color: #faad15"
            :number-style="{
              backgroundColor: '#faad15',
              color: '#fff',
            }"
          >
            <i class="fe fe-shopping-cart" style="font-size: 24px" />
          </a-badge>
        </div>
      </div>
    </div>

    <div v-if="entity.services && services.length" class="grid-container">
      <shared-service-or-room-card
        class="service-card"
        v-for="(service, index) of services"
        :key="index"
        :service="service"
        :is_vehicle="service.is_vehicle"
        :addedToKart="alreadyInKart(service)"
        @pay-for-service="() => handleServicePayment(service)"
        @add-to-kart="() => addServiceToKart(service)"
        @remove-from-kart="() => removeServiceFromKart(service)"
        @checkout-now="() => checkOutNowClickHandler(service)"
      />

      <shared-payment-modal
        :showModal="showPaymentModal"
        :services="kart"
        :entityId="entityId"
        :entityType="entity_type"
        @close-modal="showPaymentModal = false"
        @cash-payment-processed="showCashPaymentNotificationModal"
      />

      <car-rental-payment-modal
        :showModal="showRentalPaymentModal"
        :services="kart"
        :entityId="entityId"
        :entityType="entity_type"
        @close-modal="closeRentalModalHandler"
        @cash-payment-processed="showCashPaymentNotificationModal"
      />
    </div>
    <div v-else>
      <h4>{{ entityName }} has no Services yet</h4>
    </div>

    <cash-payment-notification-modal
      :showModal="showCashPaymentNotification"
      :paymentCode="cashPaymentCode"
      :transactionId="transactionId"
      @close-modal="closeCashPaymentNotificationHandler"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { cloneDeep } from "lodash";
import { GET_ENTITY } from "@/store/actions";

import SharedServiceOrRoomCard from "@/components/tourist/SharedServiceOrRoomCard.vue";
import SharedPaymentModal from "@/components/tourist/SharedPaymentModal";
import CarRentalPaymentModal from "@/components/tourist/CarRentalPaymentModal";
import CashPaymentNotificationModal from "@/components/tourist/CashPaymentNotificationModal";

export default {
  components: {
    SharedServiceOrRoomCard,
    SharedPaymentModal,
    CarRentalPaymentModal,
    CashPaymentNotificationModal,
  },
  props: {
    entity_type: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      entity: {},
      currentService: {},

      kart: [],
      cachedKart: [],

      showPaymentModal: false,
      showRentalPaymentModal: false,
      showCashPaymentNotification: false,

      cashPaymentCode: "",
      transactionId: "",
    };
  },
  computed: {
    services() {
      if (this.entity && this.entity.services) {
        const services = this.entity.services.map((serviceOrRoom) => ({
          ...serviceOrRoom,
          type: "service",
          is_vehicle:
            serviceOrRoom.vehicle_year &&
            serviceOrRoom.vehicle_model &&
            serviceOrRoom.vehicle_model.length > 0,
        }));

        let rooms = this.getValueFromSource(this.entity, "rooms", []);
        rooms = rooms.map((serviceOrRoom) => ({
          ...serviceOrRoom,
          type: "room",
        }));

        return [...services, ...rooms].filter((serviceOrRoom) => {
          const is_active_service = serviceOrRoom.status === "active";
          if (serviceOrRoom.is_vehicle) {
            return is_active_service && serviceOrRoom.rented === false;
          }

          if (serviceOrRoom.type === "room") {
            return is_active_service && serviceOrRoom.fully_booked === false;
          }
          return is_active_service;
        });
      }

      return [];
    },
    entityId() {
      return this.$route.params.id;
    },
    entityName() {
      return this.getValueFromSource(this.entity, "information.name");
    },
  },
  created() {
    this.fetchEntity();
  },
  methods: {
    ...mapActions("tourist", {
      getEntity: GET_ENTITY,
    }),
    ...mapMutations("tourist", {
      setTouristState: "SET_STATE",
    }),
    closeCashPaymentNotificationHandler() {
      this.cashPaymentCode = "";
      this.showCashPaymentNotification = false;
    },
    showCashPaymentNotificationModal({ payment_code, transaction_id }) {
      this.cashPaymentCode = payment_code;
      this.transactionId = transaction_id;
      this.showCashPaymentNotification = true;
    },
    alreadyInKart(service) {
      for (const item of this.kart) {
        if (item._id === service._id) {
          return true;
        }
      }
      return false;
    },
    removeServiceFromKart(service) {
      if (!this.alreadyInKart(service)) {
        this.$notification.info({ message: "Item not in kart!" });
        return;
      }
      this.kart = cloneDeep(this.kart).filter((k) => k._id !== service._id);
    },
    addServiceToKart(service) {
      if (this.alreadyInKart(service)) {
        this.$notification.info({ message: "Item already in kart!" });
        return;
      }
      //What did I do with serviceName? what was I thinking
      const serviceName = service.title ? service.title : service.vehicle_model;
      this.kart.push(service);
    },
    handleServicePayment(service) {
      this.currentService = service;
      this.showPaymentModal = true;
    },
    getServiceTitle(service) {
      if (service.title) return service.title;
      if (service.vehicle_model && service.vehicle_type) {
        const { vehicle_model, vehicle_type } = service;
        return `${vehicle_model} ${vehicle_type}`;
      }
    },
    async fetchEntity() {
      try {
        this.loading = true;
        const response = await this.getEntity({
          entity_type: this.entity_type,
          entity_id: this.$route.params.id,
        });
        this.entity = response.entity;
      } catch (error) {
        this.$notification.error({ message: "Error fetching entity data" });
      } finally {
        this.loading = false;
      }
    },
    getFeaturedImage(service) {
      if (service) {
        if (service.featured_images) {
          return this.getServiceFeaturedImage(service);
        }
        // service.featured_image.path
        if (service && service.featured_image) {
          return service.featured_image.path || "";
        }
        return "";
      }
    },
    kartClickHandler() {
      if (this.kart.length === 0) return;
      this.showPaymentModal = !this.showPaymentModal;
    },
    checkOutNowClickHandler(service) {
      if (this.kart.length > 0) {
        this.cachedKart = [...this.kart];
        this.kart = [];
      }
      this.addServiceToKart(service);
      this.showRentalPaymentModal = true;
    },
    getServiceFeaturedImage(service) {
      if (service.featured_images) {
        for (const image of service.featured_images) {
          if (image && image.path && image.path.length > 0) {
            return image.path;
          }
        }
      } else {
        return service.featured_image ? service.featured_image.path : "";
      }
      return "";
    },
    closeRentalModalHandler() {
      this.kart.pop();
      if (this.cachedKart.length > 0) {
        this.kart = [...this.cachedKart];
        this.cachedKart = [];
      }
      this.showRentalPaymentModal = false;
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: auto;
}

.service-card {
  /* margin-left: 5px; */
  margin-right: 25px;
}

.services-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-right: 130px;
}
</style>
